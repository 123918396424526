<template>
  <div class="entrustChild">
    <div class="banner"><img src="./img/banner.png" alt="" /></div>
    <div class="title">
      <div class="title-l">活动预约</div>
      <div class="title-r" @click="myAppoint">
        <div class="txt">我的预约</div>
        <div class="img"><img src="./img/arrow.png" alt="" /></div>
      </div>
    </div>
    <div class="time">
      <div
        v-for="(item, index) in timeList"
        :key="index"
        :class="['item', { active: index == oIndex }]"
        @click="selectTime(item, index)"
      >
        <div class="item-t">{{ item.key }}</div>
        <div class="item-b">{{ item.value }}</div>
      </div>
    </div>
    <div class="main">
      <div class="main-t">
        <div class="item" v-if="amList.length != 0">
          <div class="item-l">
            <div>
              <p>上</p>
              <p>午</p>
            </div>
          </div>
          <div class="item-c">
            <div
              class="list"
              v-for="(item, index) in postData"
              :key="index"
              v-show="item.timeDuring == '上午'"
            >
              <div class="list-l">
                <div class="list-l-c">
                  <p>{{ item.activityName }}</p>
                  <h3>{{ item.activityTime }}</h3>
                </div>
              </div>
              <div class="list-r">{{ item.activityDetail }}</div>
            </div>
          </div>
          <div class="item-r">
            <div class="item-r-c">
              <div class="btn">
                <!-- <div class="btn-l">预约结束</div> -->
                <div class="btn-r">
                  <div
                    :class="[
                      'opera1',
                      {
                        full:
                          amList[0].applicantsNum == amList[0].applicantsMax,
                      },
                    ]"
                    @click="toAppoint(amList[0].scheduleId)"
                    v-if="
                      amList[0].applicationStatus == '' ||
                      amList[0].applicationStatus == null
                    "
                  >
                    预约
                  </div>
                  <div
                    class="opera2"
                    v-if="
                      amList[0].applicationStatus == '1' &&
                      returnTime(amList[0].dayName) != indexTime
                    "
                  >
                    已预约
                  </div>
                  <div class="opera3" v-if="amList[0].applicationStatus == '0'">
                    待审核
                  </div>
                  <div
                    class="opera3"
                    v-if="
                      amList[0].applicationStatus == '1' &&
                      returnTime(amList[0].dayName) == indexTime
                    "
                    @click="toLook(amList[0].scheduleId)"
                  >
                    查看视频
                  </div>
                </div>
              </div>
              <div class="txt">
                <div
                  class="txt-c"
                  v-if="amList[0].applicantsNum != amList[0].applicantsMax"
                >
                  已预约{{ amList[0].applicantsNum }}人,剩{{
                    amList[0].applicantsMax - amList[0].applicantsNum
                  }}人
                </div>
                <div class="txt-c" v-else>已约满</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item" v-if="pmList.length != 0">
          <div class="item-l">
            <div>
              <p>下</p>
              <p>午</p>
            </div>
          </div>
          <div class="item-c">
            <div
              class="list"
              v-for="(item, index) in postData"
              :key="index"
              v-show="item.timeDuring == '下午'"
            >
              <div class="list-l">
                <div class="list-l-c">
                  <p>{{ item.activityName }}</p>
                  <h3>{{ item.activityTime }}</h3>
                </div>
              </div>
              <div class="list-r">{{ item.activityDetail }}</div>
            </div>
          </div>
          <div class="item-r">
            <div class="item-r-c">
              <div class="btn">
                <!-- <div class="btn-l">预约结束</div> -->
                <div class="btn-r">
                  <div
                    :class="[
                      'opera1',
                      {
                        full:
                          pmList[0].applicantsNum == pmList[0].applicantsMax,
                      },
                    ]"
                    @click="toAppoint(pmList[0].scheduleId)"
                    v-if="
                      pmList[0].applicationStatus == '' ||
                      pmList[0].applicationStatus == null
                    "
                  >
                    预约
                  </div>
                  <div
                    class="opera2"
                    v-if="
                      pmList[0].applicationStatus == '1' &&
                      returnTime(amList[0].dayName) != indexTime
                    "
                  >
                    已预约
                  </div>
                  <div class="opera3" v-if="pmList[0].applicationStatus == '0'">
                    待审核
                  </div>
                  <div
                    class="opera3"
                    v-if="
                      pmList[0].applicationStatus == '1' &&
                      returnTime(pmList[0].dayName) == indexTime
                    "
                    @click="toLook(pmList[0].scheduleId)"
                  >
                    查看视频
                  </div>
                </div>
              </div>
              <div class="txt">
                <div
                  class="txt-c"
                  v-if="pmList[0].applicantsNum != pmList[0].applicantsMax"
                >
                  已预约{{ pmList[0].applicantsNum }}人,剩{{
                    pmList[0].applicantsMax - pmList[0].applicantsNum
                  }}人
                </div>
                <div class="txt-c" v-else>已约满</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-empty v-if="amList.length == 0 && pmList.length == 0"></v-empty>
    </div>
  </div>
</template>

<script>
import { toRegister } from "@/utils/common";
import wx from "weixin-js-sdk";
import { weekArrMap } from "./map.js";
import { selectCourseUrl, postApply } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "entrustChild",
  data() {
    return {
      oIndex: 0,
      postData: [],
      amList: [],
      pmList: [],
      timeList: [],
      scheduleId: "",
      indexTime: moment(new Date()).format("YYYY-MM-DD"),
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  async mounted() {
    this.getTimeList();
    this.getPartyList(this.timeList[0].goal);
    if (await toRegister(this.$route.path, this.$route.query, "场馆预约")) {
    }
  },
  beforeDestroy() {},
  methods: {
    returnTime(data) {
      if (data) {
        return moment(data).format("YYYY-MM-DD");
      }
    },
    toLook(scheduleId) {
      this.$router.push({
        name: "entrustChildVideo",
        query: {
          scheduleId: scheduleId,
        },
      });
    },
    myAppoint() {
      this.$router.push({
        name: "myEntrustChild",
      });
    },
    toAppoint(scheduleId) {
      this.$router.push({
        name: "entrustChildList",
        query: {
          scheduleId: scheduleId,
        },
      });
    },
    selectTime(item, index) {
      this.oIndex = index;
      this.postData = [];
      this.amList = [];
      this.pmList = [];
      this.getPartyList(item.goal);
    },
    getTimeList() {
      this.timeList = [];
      let num = 5;
      for (var i = 0; i < num; i++) {
        let obj = {
          key: weekArrMap[moment(new Date()).add(i, "days").weekday()],
          value: moment(new Date()).add(i, "days").format("MM.DD"),
          goal: moment(new Date()).add(i, "days").format("YYYY-MM-DD"),
        };
        this.timeList.push(obj);
      }
      console.log(this.timeList);
    },
    async getPartyList(dayData) {
      let params = {
        userId: this.userId,
        dayData: dayData,
      };
      let res = await this.$axios.post(
        selectCourseUrl,
        this.$qs.stringify(params)
      );
      if (res.code === 200) {
        this.postData = res.data;
        this.postData.forEach((ele) => {
          if (ele.timeDuring == "上午") {
            this.amList.push(ele);
          }
          if (ele.timeDuring == "下午") {
            this.pmList.push(ele);
          }
        });
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.entrustChild {
  height: 100vh;
  .banner {
    height: 319px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    padding: 32px;
    display: flex;
    justify-content: space-between;
    .title-l {
      font-size: 36px;
      font-weight: 600;
      color: #1a1c34;
      line-height: 50px;
    }
    .title-r {
      display: flex;
      align-items: center;
      .txt {
        font-size: 28px;
        font-weight: 400;
        color: #1a1c34;
        line-height: 40px;
      }
      .img {
        width: 42px;
        height: 42px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .time {
    padding: 0 34px 50px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    .item {
      position: relative;
    }
    .item-t {
      font-size: 28px;
      font-weight: 400;
      color: #a8a8a8;
      line-height: 40px;
    }
    .item-b {
      font-size: 24px;
      font-weight: 400;
      color: #a8a8a8;
      line-height: 34px;
    }
    .active {
      .item-t,
      .item-b {
        color: #151c39;
      }

      &::after {
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        margin: auto;
        content: "";
        width: 64px;
        height: 8px;
        background: #007eff;
        border-radius: 4px;
      }
    }
  }
  .main {
    padding: 0 34px 30px;
    .item {
      display: flex;
      background: #ffffff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      margin-bottom: 30px;
      .item-l {
        width: 84px;
        border-right: 1px solid rgba(0, 0, 0, 0.06);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        font-weight: 600;
        color: #151c39;
        line-height: 50px;
      }
      .item-c {
        flex: 1;
        .list {
          display: flex;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          .list-l {
            flex: 1;
            flex-shrink: 0;
            padding: 14px 16px;
            box-sizing: border-box;
          }
          .list-r {
            flex: 1;
            flex-shrink: 0;
            padding: 14px 16px;
            box-sizing: border-box;
          }
          .list-l {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: 400;
            color: #151c39;
            line-height: 34px;
            border-right: 1px solid rgba(0, 0, 0, 0.06);
          }
          .list-r {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: 400;
            color: #151c39;
            line-height: 34px;
          }
        }
      }
      .item-r {
        width: 182px;
        border-left: 1px solid rgba(0, 0, 0, 0.06);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .btn {
          .btn-l {
            font-size: 28px;
            font-weight: 400;
            color: #a8a8a8;
            line-height: 40px;
          }
          .btn-r {
            .opera1 {
              width: 138px;
              height: 52px;
              background: #007eff;
              border-radius: 26px;
              font-size: 28px;
              font-weight: 400;
              color: #ffffff;
              line-height: 52px;
              margin: 0 auto;
            }
            .full {
              background: #b0b1b4;
            }
            .opera2 {
              width: 138px;
              height: 52px;
              border-radius: 26px;
              border: 2px solid #007eff;
              font-size: 28px;
              font-weight: 400;
              color: #007eff;
              line-height: 52px;
              margin: 0 auto;
            }
            .opera3 {
              font-size: 28px;
              font-weight: 400;
              color: #007eff;
              line-height: 40px;
            }
          }
        }
        .txt {
          margin-top: 8px;
          font-size: 20px;
          font-weight: 400;
          color: #b0b1b4;
          line-height: 28px;
        }
      }
    }
  }
}
.entrustChild {
}
</style>
